/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "./Header.css";



function Header() {
    return (
        <section className="section-bg">
            <div className='container py-5'>
                <div className="row mb-5 d-flex justify-content-center align-items-center">
                    <div className="col-lg-8 px-3 mb-4">
                        <div className="text-center">
                            <h1 className='display-1 fw-bold'>Title</h1>
                            <p className='lead'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur aliquam dolor, laborum alias accusantium sint.</p>
                        </div>
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col-lg-4 px-3 mb-4">
                        <div className="p-3 p-lg-4 d-flex flex-column gap-4 border border-1 item-box">
                            <h3>Subtitle</h3>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. At non laborum accusantium? Sint, qui. Repellat?</p>
                            <a href="#" className='btn btn-primary btn-sm'>Submit</a>
                        </div>
                    </div>
                    <div className="col-lg-4 px-3 mb-4">
                        <div className="p-3 p-lg-4 d-flex flex-column gap-4 border border-1 item-box">
                            <h3>Subtitle</h3>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. At non laborum accusantium? Sint, qui. Repellat?</p>
                            <a href="#" className='btn btn-primary btn-sm'>Submit</a>
                        </div>
                    </div>
                    <div className="col-lg-4 px-3 mb-4">
                        <div className="p-3 p-lg-4 d-flex flex-column gap-4 border border-1 item-box">
                            <h3>Subtitle</h3>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. At non laborum accusantium? Sint, qui. Repellat?</p>
                            <a href="#" className='btn btn-primary btn-sm'>Submit</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Header;